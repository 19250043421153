<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label='标题：'>
                    <el-input v-model='filters.title' clearable @clear='handleClear' placeholder='请输入'></el-input>
                </el-form-item>
                <el-form-item label='启用标识：'>
                    <el-select v-model="filters.isEnable" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格主体 -->
        <el-table
        highlight-current-row
        :data="tableData"
        border
        :header-cell-style="headClass"
        :cell-style="rowClass" 
        v-loading='listLoading'
        @current-change='currentChange'
        style="width: 100%;height: 100vh">
            <el-table-column type='index' label="序号" width="60"></el-table-column>
            <el-table-column prop='Title' label='标题'></el-table-column>
            <el-table-column prop='CoverPhotoWebUrl' label='图片' min-width="100">
                <template slot-scope='scope'>
                    <el-popover placement='right' trigger='hover'>
                        <img :src="scope.row.CoverPhotoWebUrl" alt="" style='width:250px;height:150px'> 
                        <img slot="reference" :src="scope.row.CoverPhotoWebUrl" alt="" style='width: auto;height: auto;max-width: 50%;max-height: 50%;'>                   
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop='LinkUrl' label='链接地址' show-overflow-tooltip min-width="200"></el-table-column>
            <el-table-column prop='IsEnable' label='启用标识' min-width="100">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsEnable' type='success'>启用</el-tag>
                    <el-tag v-else type='warning'>禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='Remarks' label='备注' min-width="200" show-overflow-tooltip></el-table-column>
        </el-table>
        <!-- 新增/编辑 -->
        <el-dialog
        :close-on-click-modal='false'
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="30%">
        <el-form :model="Form" :rules="FormRules" ref="FormRef" label-width="100px">
            <el-form-item label="标题：" prop="title">
                <el-input v-model="Form.title"></el-input>
            </el-form-item>
            <el-form-item label="图片：" prop="coverPhotoUrl">
                <el-upload
                ref='upload'
                :action="actions"
                :headers='headers'
                :file-list="fileList"
                :show-file-list="false"
                list-type="picture-card"
                :on-success='uploadSuccess'
                :on-change='handleChange'
                >
                <img v-if="imageUrl" :src="imageUrl" style='width:100%;height:100%'>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="链接地址：" prop="linkUrl">
                <el-input v-model="Form.linkUrl"></el-input>
            </el-form-item>
            <el-form-item label='启用标识：' prop='isEnable'>
                <el-select v-model="Form.isEnable" placeholder="请选择">
                    <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='备注：' prop='remarks'>
                <el-input v-model='Form.remarks' type="textarea" style="height:150px;"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogSubmit">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import Qs from 'qs';
import { getCarouselInformationList,addCarouselInformation,updateCarouselInformation,deleteCarouselInformation,uploadImagesUrl } from '../../api/api'
export default {
    components:{Toolbar},
    data(){
        var checkLinkUrl = (rule,value,callback) => {
            var reg = /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/
            if(reg.test(value)){
                return callback()
            }else if(value == ''){
                return callback()
            }else{
                return callback(new Error('链接地址不正确'))
            }
        }
        return {
            buttonList:[],
            tableData:[],
            listLoading:false,
            currentRow:null,
            filters:{
                title:'',
                isEnable:null
            },
            options:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            options1:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            dialogVisible:false,
            dialogTitle:'',
            // 表单数据
            Form:{
                addOrEdit:true,
                title:'',
                coverPhotoUrl:'',
                linkUrl:'',
                isEnable:true,
                remarks:''
            },
            FormRules:{
                title:[{required:true,message:'请输入标题',trigger:'blur'}],
                coverPhotoUrl:[{required:true,message:'请上传图片',trigger:'change'}],
                linkUrl:[{validator:checkLinkUrl,trigger:'blur'}],
                isEnable:[{required:true,trigger:'change'}],
            },
            // 上传图片
            actions:'',
            fileList:[],
            imageUrl:'',
        }
    },
    computed:{
        headers(){
            return {
                "Authorization": 'Bearer ' + localStorage.getItem("Token")
            }
        }
    },
    methods:{
        headClass(){
            return 'text-align:center;'
        },
        rowClass(){
            return 'text-align:center;'
        },
         callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取当前行数据
        currentChange(value){
            this.currentRow = value
        },
        // 查询
        getCarouselInformationList(){      
            this.getData()
        },
        // 新增
        handleAdd(){
            this.dialogTitle = '新增'
            this.imageUrl = ''
            this.Form.title = ''
            this.Form.linkUrl = ''
            this.Form.isEnable = true
            this.Form.addOrEdit = true
            this.Form.remarks = ''
            this.dialogVisible = true
        },
        // 编辑
        handleEdit(){
            var row = this.currentRow
            if(row == null){
                this.$message.warning('请选择要编辑的一行数据')
            }else{
                this.dialogTitle = '编辑'
                this.imageUrl = row.CoverPhotoWebUrl
                this.Form.addOrEdit = false
                this.dialogVisible = true
                this.Form.title = row.Title
                this.Form.coverPhotoUrl = row.CoverPhotoUrl
                this.Form.linkUrl = row.LinkUrl
                this.Form.isEnable = row.IsEnable
                this.Form.remarks = row.Remarks
            }           
        },
        // 删除
        handleDel(){
            const row = this.currentRow
            if(row == null){
                this.$message.error('请选择要删除的一行数据');
            }else{
                this.$confirm('此操作将永久删除该行数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteCarouselInformation({Id:row.ID}).then(res => {
                        if(res.data.Success){
                            this.$message({
                                message: res.data.Message,
                                type: 'success'
                            });
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message);
                        }
                    }).catch(() => {})
                }).catch(() => {})
            }
        },
        // 新增/编辑表单提交
        dialogSubmit(){
            this.$refs['FormRef'].validate((valid) => {
                if(valid && this.Form.addOrEdit == true){
                    var params = {
                        Title:this.Form.title,
                        CoverPhotoUrl:this.Form.coverPhotoUrl,
                        LinkUrl:this.Form.linkUrl,
                        IsEnable:this.Form.isEnable,
                        Remarks:this.Form.remarks
                    }
                    addCarouselInformation(params).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.dialogVisible = false
                            this.getData()
                            this.$refs['FormRef'].resetFields()
                        }else{
                            this.$message.error(res.data.Message)
                            this.dialogVisible = false
                        }
                    }).catch(() => {})
                }else if(valid && this.Form.addOrEdit == false){
                    var params = {
                        Id:this.currentRow.ID,
                        Title:this.Form.title,
                        CoverPhotoUrl:this.Form.coverPhotoUrl,
                        LinkUrl:this.Form.linkUrl,
                        IsEnable:this.Form.isEnable,
                        Remarks:this.Form.remarks
                    }
                    updateCarouselInformation(params).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.dialogVisible = false
                            this.getData()
                            this.$refs['FormRef'].resetFields()
                        }else{
                            this.dialogVisible = false
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }else{
                    return 
                }
            })           
        },
        // 对话框关闭处理
        // handleClose(){
        //     this.Form = {}
        //     this.$refs['FormRef'].resetFields()
        // },
        // 图片上传成功
        uploadSuccess(res,file){
            if(res.Success){
                this.$message.success(res.Message)
                this.imageUrl = res.Response.weburl
                this.Form.coverPhotoUrl = res.Response.webupurl
            }else{
                this.$message.eror(res.Message)
            }
        },
        handleChange(file,fileList){          
            this.$refs.upload.clearFiles();
            this.$refs.upload.uploadFiles.push(file);
        },
        // 筛选框清空，重新获取数据
        handleClear(){
            this.getData()
        },
        // 获取页面列表数据
        getData(){
            this.listLoading = true
            var params = {
                title:this.filters.title,
                isEnable:this.filters.isEnable
            }
            getCarouselInformationList(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.reverse()
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {
                this.listLoading = false
                this.$message.error(res.data.Message)
            })
        }
    },
    mounted(){
        this.getData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.actions = uploadImagesUrl
    }
}
</script>

<style lang="stylus" scoped>
    .disabled .el-upload--picture-card {
        display: none !important;
    }
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>